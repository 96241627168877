









































// Core
import { Component, PropSync, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Interfaces
import { ElForm } from 'element-ui/types/form'
import { UsersActions } from '@/store/users/types'

// Modules
const NSUsers = namespace('usersModule')

import {passwordRules, passwordDescription} from '@/views/admin/views/users/utils/passwordRules.js'

@Component({
  name: 'DialogChangePassword',
})
export default class DialogChangePasswordComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @NSUsers.Action(UsersActions.A_CHANGE_PASSWORD_USER)
  private changePassword!: (payload: {
    userId: number
    password: string
    passwordConfirmation: string,
  }) => Promise<void>

  private userId

  private desc = passwordDescription

  private form = {
    password: '',
    passwordConfirmation: '',
  }

  protected rules = {
    password: passwordRules,
    passwordConfirmation: [
      { required: true, message: 'Введите пароль', trigger: 'blur' },
      { validator: this.validatePassword, trigger: 'blur' },
    ],
  }

  created() {
    this.userId = parseInt(this.$route.params.userId, 10)
  }

  private validatePassword(_rule, value, callback) {
    if (value !== this.form.password) {
      callback(new Error('Пароли не совпадают'))
    } else {
      callback()
    }
  }

  private handleChangePassword() {
    const form = this.$refs.form as ElForm

    form.validate(isValid => {
      if (isValid) {
        this.changePassword({ userId: this.userId, ...this.form })
          .then(() => {
            this.$notify.success({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
            })
          })
          .catch(error => {
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })

        form.resetFields()

        this._visible = false
      }
    })
  }

  private handleCloseDialog() {
    const form = this.$refs.form as ElForm

    form.resetFields()

    this._visible = false
  }
}
